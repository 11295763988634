import type { FormSystemName } from "~/types/form_system_name";
import { useModalFormContext } from "~/contexts/ModalFormContext";
import { For } from "solid-js";

// @ts-expect-error json resolution not working
import program99000 from "~/../tests/fixtures/fetched/program-99000.json";
// @ts-expect-error json resolution not working
import slices99000 from "~/../tests/fixtures/fetched/program-99000-slices.json";
// @ts-expect-error json resolution not working
import lots99000 from "~/../tests/fixtures/fetched/program-99000-lots.json";

// @ts-expect-error json resolution not working
import program99001 from "~/../tests/fixtures/fetched/program-99001.json";
// @ts-expect-error json resolution not working
import slices99001 from "~/../tests/fixtures/fetched/program-99001-slices.json";
// @ts-expect-error json resolution not working
import lots99001 from "~/../tests/fixtures/fetched/program-99001-lots.json";

import { createProgramWrapper } from "~/utils/program_wrapper";
import { fixEmptyFields } from "~/lib/fetcherInternal";

export default function TestingForms() {
  const [, { openModalForm, setModalFormHeadline }] = useModalFormContext();

  const wrapper99000 = createProgramWrapper({
    program: fixEmptyFields(program99000.data),
    slices: fixEmptyFields(slices99000.data),
    lots: lots99000.data,
  });

  const wrapper99001 = createProgramWrapper({
    program: fixEmptyFields(program99001.data),
    slices: fixEmptyFields(slices99001.data),
    lots: lots99001.data,
  });

  const forms: FormSystemName[] = [
    "advisor",
    "blueprint",
    "callback",
    "leaflet",
    "qualification",
    "contact-us",
    "financial-study",
    "parking",
    "guide-invest",
    "sponsorship",
  ];

  return (
    <>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <h2>Forms</h2>

      <ul>
        <li>
          <button
            class="btn"
            onClick={() => {
              openModalForm!("in-page", { wrapper: wrapper99000 });
              setModalFormHeadline!("in-page");
            }}
          >
            in-page
          </button>
        </li>
        <For each={forms}>
          {(form) => {
            return (
              <li>
                <button
                  class="btn"
                  onClick={() => {
                    openModalForm!(form, { wrapper: wrapper99001 });
                    setModalFormHeadline!(form);
                  }}
                >
                  {form}
                </button>
              </li>
            );
          }}
        </For>
      </ul>
    </>
  );
}
